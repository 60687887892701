import React, { useEffect, useRef, useState } from 'react'
import keycloak from '../../classes/Keycloak'
import PropTypes from 'prop-types'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { apiSetToken, apiSetCurrentUserGroup } from '../../services/api'
import { useDispatch } from 'react-redux'
import { getCurrentUser, setAgreements } from '../../store/userReducer'
import * as Constants from '../../constants'
import UserAccess from '../../classes/UserAccess'
import SocketIO from '../../classes/SocketIO'
import { getPermissions } from '../../store/notification/notificationAction'
import { SET_ACCESS } from '../../store/notification/notificationTypes'
import useLoading from '../hooks/useLoading'
import { isNull } from 'url/util'

const AppAuthProvider = (props) => {
  const dispatch = useDispatch()
  const socketIO = useRef()
  const loading = useLoading()
  const [showContent, setShowContent] = useState(false)
  const authTimeout = useRef(null)

  const setAccess = (user) => {
    let groups = keycloak.tokenParsed?.groups || []
    // Создаем единственный экземпляр класса, чтобы последующие конструкторы возвращали его
    const access = new UserAccess(groups, user)
    // Установка заголовков для api
    // apiSetToken(keycloak.token)
    const currentRole = access.getCurrentRole()
    if (currentRole !== Constants.UNDEFINED_ROLE) {
      apiSetCurrentUserGroup(currentRole)
    }
  }

  const setRooms = () => {
    const rooms = []
    const access = new UserAccess()
    if (keycloak.tokenParsed?.external_id) {
      socketIO.current.setExternalId(keycloak.tokenParsed?.external_id)
      rooms.push(`user:${keycloak.tokenParsed?.external_id}`)
    }
    const currentRole = access.getCurrentRole()
    if (currentRole !== Constants.UNDEFINED_ROLE) {
      rooms.push(`group:${currentRole}`)
    }
    rooms.forEach((room) => {
      socketIO.current.join(room)
    })
  }

  useEffect(() => {
    loading.set()
    socketIO.current = new SocketIO()
    return () => {
      socketIO.current.disconnect()
    }
  }, [])

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        silentCheckSsoFallback: true,
        messageReceiveTimeout: 2000,
      }}
      LoadingComponent={<div />}
      onTokens={(tokens) => {
        if (tokens.token) {
          apiSetToken(tokens.token)
        }
      }}
      onEvent={async (event, error) => {
        switch (event) {
          case 'onReady':
            loading.set()
            if (isNull(authTimeout.current)) {
              authTimeout.current = setTimeout(() => {
                setShowContent(true)
              }, 1500)
            }
            break
          case 'onInitError':
          case 'onAuthError':
            loading.remove()
            setShowContent(true)
            if (event === 'onInitError') {
            }
            break
          case 'onAuthSuccess':
            clearTimeout(authTimeout.current)
            authTimeout.current = false
            setShowContent(false)
            loading.set()
            // Установка заголовков для api
            apiSetToken(keycloak.token)
            await dispatch(getCurrentUser()).then((user) => {
              if (user.id) {
                setAccess(user)
                const electronicAgreement = user.personal_agreement_users?.find(
                  (agreement) => agreement.type === 'electronic',
                )
                if (!electronicAgreement?.active) {
                  dispatch(
                    setAgreements(user.id, [
                      {
                        type: 'electronic',
                        value: true,
                      },
                    ]),
                  ).catch((error) => {})
                }
                dispatch(getPermissions('me')).then((response) =>
                  dispatch({ type: SET_ACCESS, data: response.data.result.data }),
                )
              } else {
                setAccess({})
              }
            })
            setRooms()
            socketIO.current.onReconnect(setRooms)
            setShowContent(true)
            break
          default:
        }
      }}
    >
      {showContent && props.children}
    </ReactKeycloakProvider>
  )
}

AppAuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

export default AppAuthProvider
