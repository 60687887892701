import React, { Component } from 'react'
import { Provider as BusProvider } from 'react-bus'
import './scss/app.scss'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import store from './store'

import English from './lang/en'
import Russian from './lang/ru'

import { registerLocale, setDefaultLocale } from 'react-datepicker'
import { enGB, ru } from 'date-fns/locale'

import AppAuthProvider from './components/providers/AppAuthProvider'
import AppContainer from './components/AppContainer'
import setMockData from './store/mockDataServis/mockServer'
import AppLoading from './components/AppLoading'

import { pdfjs } from 'react-pdf'

import 'pdfjs-dist/build/pdf.worker.entry'
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString()

const enLocale = 'en'
let locale = navigator.language
locale = locale === enLocale ? 'en' : 'ru'
let messages = locale === enLocale ? English : Russian

registerLocale('datepickerLocale', locale === enLocale ? enGB : ru)
setDefaultLocale('datepickerLocale')

const handleTranslationError = (err) => {
  // Чтобы не выводились ошибки в консоле,
  // в случае если не найдено сообщение в messages и выводится дефолтное сообщение
  //console.error(err)
}

class App extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    //TODO удалить после полной интеграции бека, не забыть удалить src/store/mockDataServis
    setMockData()
    return (
      <>
        <IntlProvider
          locale={locale}
          messages={messages}
          timeZone="utc"
          onError={handleTranslationError}
        >
          <Provider store={store}>
            <AppLoading />
            <AppAuthProvider>
              <BusProvider>
                <AppContainer />
              </BusProvider>
            </AppAuthProvider>
          </Provider>
        </IntlProvider>
      </>
    )
  }
}

if (window.Cypress) {
  window.__store__ = store
}

export default App
