import { GET_ALL_APPEALS, GET_APPEALS_BY_ID } from './appealTypes'

const initialState = {
  allAppeal: {},
}

const appealReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_APPEALS:
      return { ...prevState, allAppeal: action.data }
    case GET_APPEALS_BY_ID:
      return { ...prevState, [action.data.data.result.id]: { ...action.data.data } }
    default:
      return prevState
  }
}
export default appealReducer
