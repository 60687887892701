import {
  CREATE_HOME_TASK,
  GET_HOME_TASK,
  GET_HOME_TASK_BY_ID,
  RESET_CREATE_HOME_TASK,
  UPDATE_HOME_TASK,
  RESET_HOME_TASK,
} from './homeTaskTypes'
import { setError } from '../errorReducer'
import api from '../../services/api'

export const getAllHomeTaskUrl = '/home_tasks'

export const getAllHomeTask = (params, config = {}) => {
  return async (dispatch) => {
    api
      .get(getAllHomeTaskUrl, { ...config, params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_HOME_TASK,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getHomeTaskById = (params, config = {}) => {
  return async (dispatch) => {
    api
      .get(getAllHomeTaskUrl + '/' + params.id, { ...config })
      .then((res) =>
        dispatch({
          type: GET_HOME_TASK_BY_ID,
          id: params.id,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const createHomeTask = (body) => {
  return async (dispatch) => {
    return api
      .post(getAllHomeTaskUrl, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        dispatch({
          type: CREATE_HOME_TASK,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_CREATE_HOME_TASK,
          })
        }, 500)
        dispatch(getHomeTaskById({ id: res.data.result.id }))
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const updateHomeTask = (id, body) => {
  return async (dispatch) => {
    api
      .post(getAllHomeTaskUrl + '/' + id + '?_method=PUT', body)
      .then((res) =>
        dispatch({
          type: UPDATE_HOME_TASK,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const resetHomeTask = () => {
  return (dispatch) => {
    dispatch({ type: RESET_HOME_TASK })
  }
}
