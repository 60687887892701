export const GET_SETTING_CATEGORY_BY_ID = 'GET_SETTING_CATEGORY_BY_ID'
export const GET_SETTING_BY_ID = 'GET_SETTING_BY_ID'
export const UPDATE_SETTING_BY_ID = 'UPDATE_SETTING_BY_ID'

export const GET_ALL_RECIPIENTS_REFERENCES_DATA = 'GET_ALL_RECIPIENTS_REFERENCES_DATA'
export const GET_ALL_RECIPIENTS_APPEAL_DATA = 'GET_ALL_RECIPIENTS_APPEAL_DATA'

export const GET_RECIPIENTS_REFERENCES_DATA_BY_ID = 'GET_RECIPIENTS_REFERENCES_DATA_BY_ID'
export const GET_RECIPIENTS_APPEAL_DATA_BY_ID = 'GET_RECIPIENTS_APPEAL_DATA_BY_ID'

export const CREATE_OR_UPDATE_RECIPIENTS_REFERENCES_DATA =
  'CREATE_OR_UPDATE_RECIPIENTS_REFERENCES_DATA'
export const CREATE_OR_UPDATE_RECIPIENTS_APPEAL_DATA = 'CREATE_OR_UPDATE_RECIPIENTS_APPEAL_DATA'

export const DELETE_RECIPIENTS_APPEAL_OR_REFERENCES_DATA =
  'DELETE_RECIPIENTS_APPEAL_OR_REFERENCES_DATA'

export const RESET_SETTING_WAS_CREATE_OR_UPDATE = 'RESET_SETTING_WAS_CREATE_OR_UPDATE'
export const RESET_SETTING_WAS_DELETED = 'RESET_SETTING_WAS_DELETED'

export const GET_LESSON_EDITING_PROHIBITIONS = 'GET_LESSON_EDITING_PROHIBITIONS'
export const GET_LESSON_EDITING_PROHIBITIONS_BY_ID = 'GET_LESSON_EDITING_PROHIBITIONS_BY_ID'

export const CREATE_LESSON_EDITING_PROHIBITIONS = 'CREATE_LESSON_EDITING_PROHIBITIONS'
export const UPDATE_LESSON_EDITING_PROHIBITIONS = 'UPDATE_LESSON_EDITING_PROHIBITIONS'
export const RESET_LESSON_EDITING_PROHIBITIONS_WAS_CREATE_OR_UPDATE =
  'RESET_LESSON_EDITING_PROHIBITIONS_WAS_CREATE_OR_UPDATE'

export const DELETE_LESSON_EDITING_PROHIBITIONS = 'DELETE_LESSON_EDITING_PROHIBITIONS'
export const RESET_LESSON_EDITING_PROHIBITIONS_WAS_DELETED =
  'RESET_LESSON_EDITING_PROHIBITIONS_WAS_DELETED'

export const GET_HEADMANS_MANAGEMENT_ITEMS = 'GET_HEADMANS_MANAGEMENT_ITEMS'
export const GET_HEADMANS_MANAGEMENT_ITEM = 'GET_HEADMANS_MANAGEMENT_ITEM'
export const CLEAR_HEADMANS_MANAGEMENT_ITEM = 'CLEAR_HEADMANS_MANAGEMENT_ITEM'
