import {
  CREATE_HOME_TASK,
  GET_HOME_TASK,
  GET_HOME_TASK_BY_ID,
  RESET_CREATE_HOME_TASK,
  UPDATE_HOME_TASK,
  RESET_HOME_TASK,
} from './homeTaskTypes'

const initialState = {
  allHomeTask: {
    result: {},
  },
  homeTask: null,
  homeTaskWasCreated: false,
  homeTaskWasUpdated: false,
}
/**
 * Хранилище для домашних заданий
 * @param prevState
 * @param action
 * @returns {null|*}
 */

const homeTaskReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case GET_HOME_TASK:
      return { ...prevState, allHomeTask: { ...action.data.data } }
    case GET_HOME_TASK_BY_ID:
      return {
        ...prevState,
        homeTask: action.data.data.result,
      }
    case CREATE_HOME_TASK:
      return {
        ...prevState,
        homeTaskWasCreated: true,
      }
    case UPDATE_HOME_TASK:
      return {
        ...prevState,
        homeTaskWasUpdated: true,
      }
    case RESET_CREATE_HOME_TASK:
      return {
        ...prevState,
        homeTaskWasCreated: false,
      }
    case RESET_HOME_TASK:
      return {
        ...prevState,
        homeTask: null,
        homeTaskWasUpdated: false,
      }
    default:
      return prevState
  }
}

export default homeTaskReducer
