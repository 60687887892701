import api from '../services/api'
import { setError } from './errorReducer'

const initialState = {}

export const EDIT_USER = 'EDIT_USER'
export const SET_USER = 'SET_USER'
export const GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS'
export const SET_NOTIFICATION_SETTINGS = 'SET_NOTIFICATION_SETTINGS'
export const SET_LINKS = 'SET_LINKS'
export const SET_ACTION_USER = 'SET_ACTION_USER'

/**
 * Временное хранилище для ползователей
 * @param state
 * @param action
 * @returns {null|*}
 */
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.data }
    case EDIT_USER:
      for (let key in action.data) {
        state = { ...state, [key]: action.data[key] }
      }
      return state
    case SET_LINKS:
      return {
        ...state,
        user_links: action.data,
      }
    case GET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: action.data,
      }
    case SET_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: {
          ...state.notificationSettings,
          ...action.data,
        },
      }
    case SET_ACTION_USER:
      return {
        ...state,
        actionUser: action.data,
      }
    default:
      return state
  }
}

export default userReducer

// BEGIN: Actions
export const setUserAction = (data) => ({
  type: SET_USER,
  data,
})

export const editUserAction = (data) => ({
  type: EDIT_USER,
  data,
})

export const getLinks = (id) => {
  return async (dispatch) => {
    const url = `/users/federated-identity/${id}`
    return api
      .get(url, {})
      .then((response) => response)
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}

export const unbindUser = () => {
  return async (dispatch) => {
    const url = '/users/unlink'
    return api
      .post(url, {})
      .then((res) => res)
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}

export const getNotificationSettings = (config = {}) => {
  return async (dispatch) => {
    const url = '/notification_settings/me'
    return api
      .get(url, { ...config })
      .then((response) =>
        dispatch({
          type: GET_NOTIFICATION_SETTINGS,
          data: response.data.result,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}

export const setNotificationSettings = (params = {}) => {
  return async (dispatch) => {
    const url = '/notification_settings'
    return api
      .post(url, params)
      .then()
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}

export const getCurrentUser = (config = {}) => {
  return async (dispatch) => {
    const url = '/users/me'
    return api
      .get(url, config)
      .then((response) => {
        response.data.result.second_emails = response.data.result?.second_emails || []
        response.data.result.second_phones = response.data.result?.second_phones || []
        dispatch(setUserAction(response.data.result))
        return response.data.result
      })
      .catch((error) => {
        if (error.response?.status !== 404) {
          dispatch(setError(error.response?.status, error.message, error))
        } else {
          dispatch(setUserAction({ notFound: true }))
        }
      })
  }
}

export const setAgreements = (userId, data) => {
  return async (dispatch) => {
    const url = `/users/${userId}/agreement?_method=PUT`
    return api
      .post(url, data)
      .then((response) => response)
      .catch((error) => {
        dispatch(setError(error.response?.status, error.message, error))
        return Promise.reject(error)
      })
  }
}

export const setPassword = (userId, data, usePublic = false) => {
  return async () => {
    const url = `${usePublic ? '/public_content' : ''}/users/${userId}/password`
    return api
      .post(url, {
        password: data?.password,
        action: data?.action,
      })
      .then((response) => response)
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

export const checkAction = (action, config = {}, usePublic = false) => {
  return async (dispatch) => {
    const url = `${usePublic ? '/public_content' : ''}/users/actions/check`
    return api
      .get(url, {
        ...config,
        params: {
          action: action,
        },
      })
      .then((response) => {
        dispatch({
          type: SET_ACTION_USER,
          data: response.data.result.user,
        })
        return response
      })
      .catch((error) => {
        dispatch({
          type: SET_ACTION_USER,
          data: null,
        })
        dispatch(setError(error.response?.status, error.message, error))
        return Promise.reject(error)
      })
  }
}
// END: Actions
