import {
  CREATE_LESSON_EDITING_PROHIBITIONS,
  CREATE_OR_UPDATE_RECIPIENTS_APPEAL_DATA,
  CREATE_OR_UPDATE_RECIPIENTS_REFERENCES_DATA,
  DELETE_LESSON_EDITING_PROHIBITIONS,
  DELETE_RECIPIENTS_APPEAL_OR_REFERENCES_DATA,
  GET_ALL_RECIPIENTS_APPEAL_DATA,
  GET_ALL_RECIPIENTS_REFERENCES_DATA,
  GET_LESSON_EDITING_PROHIBITIONS,
  GET_LESSON_EDITING_PROHIBITIONS_BY_ID,
  GET_RECIPIENTS_APPEAL_DATA_BY_ID,
  GET_RECIPIENTS_REFERENCES_DATA_BY_ID,
  GET_SETTING_BY_ID,
  GET_SETTING_CATEGORY_BY_ID,
  RESET_LESSON_EDITING_PROHIBITIONS_WAS_CREATE_OR_UPDATE,
  RESET_LESSON_EDITING_PROHIBITIONS_WAS_DELETED,
  RESET_SETTING_WAS_CREATE_OR_UPDATE,
  RESET_SETTING_WAS_DELETED,
  UPDATE_LESSON_EDITING_PROHIBITIONS,
  UPDATE_SETTING_BY_ID,
  GET_HEADMANS_MANAGEMENT_ITEMS,
  GET_HEADMANS_MANAGEMENT_ITEM,
  CLEAR_HEADMANS_MANAGEMENT_ITEM,
} from './settingTypes'
import { setError } from '../errorReducer'
import api from '../../services/api'

export const getSettingCategoryByIdUrl = '/settings/categories'
export const getSettingByIdUrl = '/settings'
export const lessonEditingProhibitionsUrl = '/lesson_editing_prohibitions'
export const headmansManagementUrl = '/headman_default_recipients'

export const getSettingCategoryById = (params, config = {}) => {
  return async (dispatch) => {
    return api
      .get(getSettingCategoryByIdUrl, { ...config, params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_SETTING_CATEGORY_BY_ID,
          parentCategoryId: params.parentCategoryId,
          data: res,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
          return Promise.reject()
        }
      })
  }
}
export const getSettingByCatId = (params, config = {}) => {
  return async (dispatch) => {
    return api
      .get(getSettingByIdUrl, { ...config, params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_SETTING_BY_ID,
          settingId: params.categoryId,
          data: res,
        }),
      )
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') {
          dispatch(setError(err.response?.status, err.message, err))
        }
      })
  }
}

export const updateSetting = (id, data) => {
  return async (dispatch) => {
    return api
      .post(`${getSettingByIdUrl}/${id}?_method=PUT`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) =>
        dispatch({
          type: UPDATE_SETTING_BY_ID,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
        return Promise.reject()
      })
  }
}

export const getSettingRecipientsReferencesUrl = '/cert_order_default_recipients'

export const getSettingRecipientsReferencesAllData = (params, config = {}) => {
  return async (dispatch) => {
    return api
      .get(getSettingRecipientsReferencesUrl, { params: { ...params }, ...config })
      .then((res) =>
        dispatch({
          type: GET_ALL_RECIPIENTS_REFERENCES_DATA,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const createSettingRecipientsReferences = (body) => {
  return async (dispatch) => {
    return api
      .post(getSettingRecipientsReferencesUrl, body)
      .then((res) => {
        dispatch({
          type: CREATE_OR_UPDATE_RECIPIENTS_REFERENCES_DATA,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_SETTING_WAS_CREATE_OR_UPDATE,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const updateSettingRecipientsReferences = (body) => {
  return async (dispatch) => {
    return api
      .put(getSettingRecipientsReferencesUrl + '/' + body.id, body)
      .then((res) => {
        dispatch({
          type: CREATE_OR_UPDATE_RECIPIENTS_REFERENCES_DATA,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_SETTING_WAS_CREATE_OR_UPDATE,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const deleteSettingRecipientsReferences = (params) => {
  return async (dispatch) => {
    return api
      .delete(getSettingRecipientsReferencesUrl + '/' + params.id)
      .then(() => {
        dispatch({
          type: DELETE_RECIPIENTS_APPEAL_OR_REFERENCES_DATA,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_SETTING_WAS_DELETED,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getSettingRecipientsReferencesByID = (params) => {
  return async (dispatch) => {
    return api
      .get(getSettingRecipientsReferencesUrl + '/' + params.id, { params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_RECIPIENTS_REFERENCES_DATA_BY_ID,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getSettingRecipientsAppealUrl = '/appeal_default_recipients'

export const getSettingRecipientsAppealAllData = (params, config = {}) => {
  return async (dispatch) => {
    return api
      .get(getSettingRecipientsAppealUrl, { params: { ...params }, ...config })
      .then((res) =>
        dispatch({
          type: GET_ALL_RECIPIENTS_APPEAL_DATA,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const createSettingRecipientsAppeal = (body) => {
  return async (dispatch) => {
    return api
      .post(getSettingRecipientsAppealUrl, body)
      .then((res) => {
        dispatch({
          type: CREATE_OR_UPDATE_RECIPIENTS_APPEAL_DATA,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_SETTING_WAS_CREATE_OR_UPDATE,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const updateSettingRecipientsAppeal = (body) => {
  return async (dispatch) => {
    return api
      .put(getSettingRecipientsAppealUrl + '/' + body.id, body)
      .then((res) => {
        dispatch({
          type: CREATE_OR_UPDATE_RECIPIENTS_APPEAL_DATA,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_SETTING_WAS_CREATE_OR_UPDATE,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const deleteSettingRecipientsAppeal = (params) => {
  return async (dispatch) => {
    return api
      .delete(getSettingRecipientsAppealUrl + '/' + params.id)
      .then(() => {
        dispatch({
          type: DELETE_RECIPIENTS_APPEAL_OR_REFERENCES_DATA,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_SETTING_WAS_DELETED,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getSettingRecipientsAppealByID = (params) => {
  return async (dispatch) => {
    return api
      .get(getSettingRecipientsAppealUrl + '/' + params.id, { params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_RECIPIENTS_APPEAL_DATA_BY_ID,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getLessonEditingProhibitions = (params, config) => {
  return async (dispatch) =>
    api
      .get(lessonEditingProhibitionsUrl, { params: { ...params }, ...config })
      .then((res) =>
        dispatch({
          type: GET_LESSON_EDITING_PROHIBITIONS,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
}

export const getLessonEditingProhibitionsByID = (params) => {
  return async (dispatch) => {
    return api
      .get(lessonEditingProhibitionsUrl + '/' + params.id, { params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_LESSON_EDITING_PROHIBITIONS_BY_ID,
          data: res,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const createLessonEditingProhibitions = (body) => {
  return async (dispatch) => {
    return api
      .post(lessonEditingProhibitionsUrl, body)
      .then((res) => {
        dispatch({
          type: CREATE_LESSON_EDITING_PROHIBITIONS,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_LESSON_EDITING_PROHIBITIONS_WAS_CREATE_OR_UPDATE,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const updateLessonEditingProhibitions = (body) => {
  return async (dispatch) => {
    return api
      .put(lessonEditingProhibitionsUrl + '/' + body.id, body)
      .then((res) => {
        dispatch({
          type: UPDATE_LESSON_EDITING_PROHIBITIONS,
          data: res,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_LESSON_EDITING_PROHIBITIONS_WAS_CREATE_OR_UPDATE,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const deleteLessonEditingProhibitions = (id) => {
  return async (dispatch) => {
    return api
      .delete(lessonEditingProhibitionsUrl + '/' + id)
      .then(() => {
        dispatch({
          type: DELETE_LESSON_EDITING_PROHIBITIONS,
        })
        setTimeout(() => {
          dispatch({
            type: RESET_LESSON_EDITING_PROHIBITIONS_WAS_DELETED,
          })
        }, 500)
      })
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
  }
}

export const getHeadmansManagementItems = (params, config) => {
  return async (dispatch) =>
    api
      .get(headmansManagementUrl, { params: { ...params }, ...config })
      .then((res) =>
        dispatch({
          type: GET_HEADMANS_MANAGEMENT_ITEMS,
          data: res.data.result,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
}

export const getHeadmansManagementItem = (params) => {
  return async (dispatch) =>
    api
      .get(`${headmansManagementUrl}/${params.id}`, { params: { ...params } })
      .then((res) =>
        dispatch({
          type: GET_HEADMANS_MANAGEMENT_ITEM,
          data: res.data.result,
        }),
      )
      .catch((err) => {
        dispatch(setError(err.response?.status, err.message, err))
      })
}

export const createHeadmansManagementItem = (body) => {
  return async (dispatch) => {
    return api.post(headmansManagementUrl, body).catch((err) => {
      dispatch(setError(err.response?.status, err.message, err))
    })
  }
}

export const updateHeadmansManagementItem = (id, body) => {
  return async (dispatch) => {
    return api.put(headmansManagementUrl + '/' + id, body).catch((err) => {
      dispatch(setError(err.response?.status, err.message, err))
    })
  }
}

export const deleteHeadmansManagementItem = (id) => {
  return async (dispatch) => {
    return api.delete(headmansManagementUrl + '/' + id).catch((err) => {
      dispatch(setError(err.response?.status, err.message, err))
    })
  }
}

export const clearHeadmansManagementItem = () => (dispatch) =>
  dispatch({
    type: CLEAR_HEADMANS_MANAGEMENT_ITEM,
  })
